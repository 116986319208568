import {ayahList} from "./ayahList";
import {useEffect, useState} from "react";


export const useAyahList = () => {

    const [loaded, setLoaded] = useState(0);

    useEffect(() => {

        setLoaded(0);

        const storedList = JSON.parse(localStorage.getItem('ayahList'));
        if (storedList) ayahList.ayah = storedList;

        if (ayahList.ayah.length) {
            setLoaded(1);
        } else {

            import("../resources/json/ayah.json").then(({default: data}) => {
                if (data) {
                    ayahList.ayah = data;
                    localStorage.setItem('ayahList', JSON.stringify(ayahList.ayah));
                    setLoaded(1);
                }
            });

        }


    }, []);

    return loaded ? ayahList.ayah : [];

};