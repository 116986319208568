import React from 'react';
import logo from "assets/images/appicon-light-96.png";
import {Link} from "react-router-dom";
import DrHilal from "assets/images/dr-mhd-hilal.png";
import Slide from "@mui/material/Slide";
import {IS_MOBILE_APP} from "../../../../theme";
import {useLocation} from "react-router";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const Menu = props => {

    const location = useLocation();

    return (
        <Slide in timeout={600} direction={"down"} mountOnEnter unmountOnExit>
            <nav>
                {IS_MOBILE_APP && location.pathname !=="/" ?
                    <Link to={"/"}>
                        <IconButton style={{color: "white"}}><ArrowForwardIcon/></IconButton>
                     </Link>
                    :
                <Link to={"/"}><img src={logo} alt="تفسير القرآن الثري الجامع"/></Link>}
                {IS_MOBILE_APP ? '' : (props.contentPage ?
                    <Link to={"/"}>عودة إلى الرئيسية</Link> :
                <Link to={"/about"}>عن التفسير</Link>)}

                <aside>
                    <img src={DrHilal} alt={"دراسة وإعداد الدكتور محمد الهلال"}/>
                </aside>
            </nav>
        </Slide>
    );

};

export default Menu;
