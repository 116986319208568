import React from 'react';
import style from "./ResultItem.module.scss";
import {stripTags} from "lib/stripTags";
import {Link} from "react-router-dom";
import {ayahUrl} from "../../Mushaf/Index/SuraIndex/ayahUrl";
import {suraList} from "../../Mushaf/store/suraList";

const ResultItem = props => {


    return suraList ? (
        <section className={style.ResultItem}>
            <h3>
                <Link to={ayahUrl(props.result.sura, props.result.ayah)}>
                    <span>{suraList.sura ? suraList.sura[parseInt(props.result.sura) - 1].name : ""} </span>
                    [{props.result.sura}:{props.result.ayah}]
                </Link>
            </h3>
            <div>
                <Link to={ayahUrl(props.result.sura, props.result.ayah)}>
                    <p>{stripTags(props.result.text)}</p>
                </Link>
            </div>
        </section>
    ) : 'loading...';

};

export default ResultItem;
