export const isJson = (obj) => {
    try {

        if (obj && typeof obj === "object") {
           return true;
        } else {
            JSON.parse(obj);
        }

    } catch (e) {
        //alert(e.message);
        return false;
    }
    return true;
}