import React, {useEffect} from 'react';
import style from "./TobBar.module.scss";
import LogoText from "assets/images/tafsir-althary-text.png";
import IconButton from "@mui/material/IconButton";
import SearchIcon from '@mui/icons-material/Search';
import Button from "@mui/material/Button";
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useNavigate} from "react-router";
import {APP_TITLE} from "../../../../theme";

const TobBar = props => {

    const navigate =  useNavigate();

    useEffect(() => {
        document.title = props.suraArabic ?
            APP_TITLE + " - " + props.suraArabic + " [" + props.sura + ":" + props.ayah + "]" : APP_TITLE;
    }, [props.sura, props.ayah, props.suraArabic]);  // eslint-disable-line

    return (
        <header className={style.TopBar}>
            <nav>
                <img src={LogoText} alt={APP_TITLE} className="logo-btn" onClick={() => navigate("/")}/>
                <Button onClick={props.openIndexHandler}>{props.suraArabic}</Button>
                <nav>
                    <Button className="index-btn" onClick={props.openIndexHandler} startIcon={<MenuBookIcon/>} size={"large"}>
                        <span>{props.sura}:{props.ayah}</span> <ArrowDropDownIcon/> </Button>
                    <IconButton size={"large"} onClick={() => navigate("/")}><SearchIcon/></IconButton>
                </nav>
            </nav>
        </header>
    );

};

export default TobBar;
