import React, {useEffect} from 'react';
import {Fab} from "@mui/material";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import {cordovaExternalLink} from "lib/cordovaTools/cordovaExternalLink";
import {IS_MOBILE_APP} from "theme";


const IntroLinks = () => {


    useEffect(() => {
        document.title = 'مقدمة المؤلف الدكتور محمد الهلال - تفسير القرآن الثري الجامع';
    }, []);

    return (
        <div>
            {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
            <Fab color="secondary" aria-label="مقدمة التفسير" style={{
                position: "fixed",
                bottom: IS_MOBILE_APP ? "70px" : "35px",
                left: "35px",
                color: "#000000 !important"
            }}
                 href={"https://tafsiralthary.com/resources/tafsir-intro.pdf"}
                 target={"_blank"}
                 onClick={cordovaExternalLink}
            >
                <SimCardDownloadIcon/>
            </Fab>
            <ul>
                <li>
                    {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href="https://tafsiralthary.com/resources/tafsir-intro-naif.pdf" target={"_blank"} onClick={cordovaExternalLink}>
                        مقدمة الأستاذ الدكتور عمر شاهين مؤسس ورئيس اتحاد الأئمة بأمريكا الشمالية سابقاً
                    </a>
                </li>
                <li>
                    {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href="https://tafsiralthary.com/resources/tafsir-intro-sciana.pdf" target={"_blank"} onClick={cordovaExternalLink}>
                        تقديم من الدكتور إسماعيل الشيخ رئيس المجلس الأعلى للشؤون الإسلامية بأمريكا الشمالية
                    </a>
                </li>
            </ul>
        </div>
    );

};

export default IntroLinks;
