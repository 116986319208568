import {cordovaReadFile, cordovaReadFileBinary, cordovaReadFileImage, cordovaReadFileJson} from "./cordovaReadFile";
import {cordovaWriteFile} from "./cordovaWriteFile";


let __cdv_def_dir = null;

const __cordovaDefaultDirectory = () => {

    if (__cdv_def_dir) return __cdv_def_dir;

    __cdv_def_dir = window.cordova.file.dataDirectory;

    if (window.cordova.platformId.toUpperCase() === 'ANDROID') {

        __cdv_def_dir = window.cordova.file.externalDataDirectory;

    } else if (window.cordova.platformId.toUpperCase() === 'IOS') {

        __cdv_def_dir = window.cordova.file.syncedDataDirectory;

    }

    return __cdv_def_dir;

}



const cordovaFileSystem = {

    read: cordovaReadFile,

    write: cordovaWriteFile,

    readJson: cordovaReadFileJson,

    readBinary: cordovaReadFileBinary,

    readImage: cordovaReadFileImage,

    defaultDirectory: __cordovaDefaultDirectory,

    setDefaultDirectory: (dir) => {
        __cdv_def_dir = dir
    }

};


export default cordovaFileSystem;


/**
 *

 function listDir(path){
        window.resolveLocalFileSystemURL(path,
            function (fileSystem) {
                var reader = fileSystem.createReader();
                reader.readEntries(
                    function (entries) {
                        alert(JSON.stringify(entries));
                    },
                    function (err) {
                        console.log(err);
                    }
                );
            }, function (err) {
                console.log(err);
            }
        );
    }
 //example: list of www/audio/ folder in cordova/ionic app.
 listDir(window.cordova.file.applicationDirectory + "www/resources/");
 */