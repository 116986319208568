import React, {useCallback, useEffect, useState} from 'react';
import SwipeableViews from "react-swipeable-views";

const PageLayout = props => {

    const startIndex = props.pageNo ? parseInt(props.pageNo) - 1 : 1;

    const [index, setIndex] = useState(startIndex);

    useEffect(() => {

        setIndex(props.pageNo ? parseInt(props.pageNo) - 1 : 1);

        clearTimeout(window.wheelOnMushafPageTimeout);
        window.wheelOnMushafPageTimeout = setTimeout(() => {
            window.wheelOnMushafPage = true;
        }, 750);


    }, [props.pageNo]); // eslint-disable-line

    const wheelHandler = useCallback((e) => {
        if (window.wheelOnMushafPage) {
            const isScrollingDown = Math.sign(e.deltaY) > 0;
            window.wheelOnMushafPage = false;
            props.onPageChange(e,
                isScrollingDown ?
                    parseInt(props.pageNo) + 1 :
                    parseInt(props.pageNo) - 1);
        }
    }, [props.pageNo]); // eslint-disable-line

    const children = React.Children.toArray(props.children);

    const pN = parseInt(props.pageNo);

    const pages = [...Array(604).keys()].map((item, index) => {
        const firstPg = pN === 1;
        const lastPg = pN === 604;
        if ((!firstPg && index === pN-2)) {
            return (
                <>{children[0]}</>
            );
        } else if ((firstPg && index === 0) || (!firstPg && (index === pN-1)  ) ) {
            return <>{children[1]}</>
        } else if ((!lastPg && (index === pN)  ) ) {
            return <>{children[2]}</>
        } else  {
            return <section dir={"rtl"} key={index}> </section>
        }
    });


    return (
        <div dir={"rtl"} onWheel={wheelHandler}>
            <SwipeableViews
                axis="x-reverse"
                index={index}
                onChangeIndex={(index) => {
                    setIndex(index);
                    props.onPageChange(null, index + 1);
                }}
            >
                {pages}
            </SwipeableViews>
        </div>
    );

};

export default PageLayout;


//import SwipeableViews from "react-swipeable-views";

//const [index, setIndex] = useState(1);

/*<SwipeableViews
                index={index}
                onChangeIndex={(index) => {
                    if (index === 0) props.onPageChange(null, parseInt(props.pageNo) + 1);
                    if (index === 2) props.onPageChange(null, parseInt(props.pageNo) - 1);
                    setTimeout(() => {
                        setIndex(1)
                    }, 3);
                }}
            >
                <section>
                    p1
                </section>*/

/*<section>p3</section>
</SwipeableViews>*/