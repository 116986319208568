import React from 'react';
import {useSearchResult} from "./store/useSearchResult";
import ResultsList from "./Result/ResultsList";
import FlippingBook from "assets/images/loading-book.gif";

function SearchResult(props) {

    const keyword = props.filter.trim();

    const results = useSearchResult(keyword);

    return results ? <ResultsList results={results} keyword={keyword}/> :

        <div style={{
            textAlign: "center",
            margin: "auto",
            padding: "4em",
            opacity: "0.9"
        }}><img src={FlippingBook} style={{

        }} alt={"جاري البحث..."}/></div>;

}

export default SearchResult;
