import React from 'react';

const Word = props => {

    const opts = {};

    if (props.data.sura !== undefined && props.data.sura.toString() === props.activeSura.toString() && props.data.ayah.toString() === props.activeAyah.toString()) {
        opts['active']='true'
    }

    return (
        <span
            data-sura={props.data.sura === undefined ? '' : props.data.sura}
            data-ayah={props.data.ayah === undefined ? '' : props.data.ayah}
            data-pos={props.data.pos}
            onClick={props.clickHandler}
            onMouseOver={props.mouseOverHandler}
            onMouseOut={props.mouseOutHandler}
            {...opts}
        >
            {props.data.uni}
        </span>
    );

};

export default Word;
