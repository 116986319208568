import React from 'react';
import {useQuranDb} from "../store/useQuranDb";
import PageContent from "./PageContent";

const PreviousPage = props => {

    const {pageNo, page, ...commonProps} = props;

    const curPageNo = parseInt(pageNo);

    const previousPageNo = curPageNo === 1 ? 1 : curPageNo - 1;

    const previousPage = useQuranDb(previousPageNo);


    return (
        <PageContent page={previousPage} pageNo={previousPageNo} {...commonProps} />
    );
    
};

export default PreviousPage;
