export const ayahList = {
    ayah : []
}

/**
 *  Array Pattern
 *
 *  {
 *      ayah: [
 *          @sura (0 based index) [
 *              @ayah (0 based index) [@juz, @page],
 *              @ayah [@juz, @page],
 *              @ayah [@juz, @page],
 *             ...
 *          ],
 *          @sura [...],
 *          ...
 *      ]
 *  }
 */