import React from 'react';
import SignalWifiConnectedNoInternet4Icon from '@mui/icons-material/SignalWifiConnectedNoInternet4';

const NetworkError = () => {

    return (
        <div style={{
            display: "flex",
            height: "40vh",
            flexFlow: "column",
            opacity: "0.6",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center"
        }}>
            <SignalWifiConnectedNoInternet4Icon/>
            <h5>خطأ في الاتصال بالشبكة!</h5>
        </div>
    );

};

export default NetworkError;
