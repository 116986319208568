import cordovaFileSystem from "./cordovaFileSystem";

export const cordovaReadFile = (fileName, dir= null, isJson = false, mimeType = null) => {

    if (dir === null) dir = cordovaFileSystem.defaultDirectory();

    else if (dir.startsWith('www/')) {
        dir = window.cordova.file.applicationDirectory + dir;
    }

    return new Promise((resolve, reject) => {

        const readFile = (fileEntry) => {

            fileEntry.file(function (file) {
                const reader = new FileReader();

                reader.onloadend = function () {
                    let data = this.result;
                    if (isJson) {
                        try {
                            data = JSON.parse(this.result);
                        } catch {}
                    } else if (mimeType) {
                        data = new Blob([new Uint8Array(this.result)], { type: mimeType });
                    }
                    resolve(data, fileEntry.fullPath);
                };

                mimeType ? reader.readAsArrayBuffer(file) : reader.readAsText(file);

            }, (e) => {
                reject(e);
            });

        }


        window.resolveLocalFileSystemURL(dir, function (dirEntry) {

            dirEntry.getFile(fileName, {create: false, exclusive: false}, function (fileEntry) {

                readFile(fileEntry);

            }, (e) => reject(e));
        }, (e) => reject(e));


    });

}

export const cordovaReadFileJson = (fileName, dir= null) => {
    return cordovaReadFile(fileName, dir, true);
}

export const cordovaReadFileBinary = (fileName, dir= null, mimeType = "application/octet-stream") => {
    return cordovaReadFile(fileName, dir, false, mimeType);
}

export const cordovaReadFileImage = (fileName, dir= null, mimeType = "image/png") => {
    return cordovaReadFile(fileName, dir, false, mimeType);
}
