import React, {useState} from 'react';
import style from "../SuraIndex/SuraIndex.module.scss";
import {useJuzList} from "../../store/useJuzList";
import {ayahUrl} from "../SuraIndex/ayahUrl";

const JuzIndex = props => {

    const [filter, setFilter] = useState('');


    let juzListView = '';


    const juzList = useJuzList();



    if (juzList.length > 0) {

        juzListView = juzList.filter(juz => juz.juz_arabic.includes(filter) || juz.juz.toString() === filter || filter === '').map(juz => {

            return (
                <li key={juz.juz}>
                    <a href={ayahUrl(juz.first_sura, juz.first_ayah)}
                       onClick={props.onIndexAyahSelected}
                       data-sura={juz.first_sura}
                       data-ayah={juz.first_ayah}
                    >
                        <em>{juz.juz}</em>
                        <span>{juz.juz_arabic}</span>
                    </a>
                </li>)
        });

    }

    return (
        <div className={style.SuraIndex}>
            <section>
                <input id="filter-juz"
                       name="filter"
                       type="text"
                       value={filter}
                       onChange={e => setFilter(e.target.value)}
                       placeholder={"الجزء ..."}
                />
            </section>
            <nav>
                <ul>
                    {juzListView}
                </ul>
            </nav>
        </div>
    );

    
};

export default JuzIndex;
