import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import 'assets/styles/index.scss';
import App from './App';
import {BrowserRouter , HashRouter} from "react-router-dom";


import './axios';


import {RTL} from "./RTL";
import {IS_MOBILE_APP, theme} from './theme';
import {ThemeProvider} from "@mui/material/styles";
import SplashScreen from "./components/Home/Loader/SplashScreen";

const ActiveRouter = IS_MOBILE_APP ? HashRouter : BrowserRouter;

const startApp = () => {

    const appContent = (
        <React.StrictMode>
            <RTL>
                <ThemeProvider theme={theme}>
                    <Suspense fallback={<SplashScreen />}>
                        <ActiveRouter>
                            <App/>
                        </ActiveRouter>
                    </Suspense>
                </ThemeProvider>
            </RTL>
        </React.StrictMode>
    );

    ReactDOM.render(
        appContent,
        document.getElementById('root')
    );
}

if (window.cordova) {
    document.addEventListener('deviceready', startApp, false);
} else {
    startApp();
}





// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
