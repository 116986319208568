import React from 'react';


const FontsPreloader = props => {

    const {page, pageNo} = props;

    const pN = pageNo ? parseInt(pageNo) : 1;

    return <>
        {page ? (<>
            <link rel="preload" as="font" href={props.path + "QCF_P" + (pageNo).toString().padStart(3, '0') + ".TTF"}
                  type="font/ttf" crossOrigin="anonymous"/>
            <link rel="preload" as="font"
                  href={props.path + "QCF_P" + (pN < 604 ? pN + 1 : 604).toString().padStart(3, '0') + ".TTF"}
                  type="font/ttf" crossOrigin="anonymous"/>
            <link rel="preload" as="font"
                  href={props.path + "QCF_P" + (pN > 1 ? pN - 1 : 1).toString().padStart(3, '0') + ".TTF"}
                  type="font/ttf" crossOrigin="anonymous"/>
        </>) : ''}
    </>;

};

export default FontsPreloader;
