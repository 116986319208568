import cordovaFileSystem from "./cordovaFileSystem";

export const cordovaWriteFile = (fileName, dataObj, isAppend = false, dir = null) => {

    if (dir === null) dir = cordovaFileSystem.defaultDirectory();

    return new Promise((resolve, reject) => {

        const writeFile = (fileEntry, dataObj, isAppend) => {

            fileEntry.createWriter(function (fileWriter) {

                fileWriter.onwriteend = () => resolve();

                fileWriter.onerror = (e) => reject(e);

                // If we are appending data to file, go to the end of the file.
                if (isAppend) {
                    try {
                        fileWriter.seek(fileWriter.length);
                    } catch (e) {
                        reject(e);
                    }
                }

                fileWriter.write(dataObj);

            });
        }

        window.resolveLocalFileSystemURL(dir, (dirEntry) => {

                dirEntry.getFile(

                    fileName,
                    {create: true, exclusive: false},
                    (fileEntry) => {

                        writeFile(fileEntry, dataObj, isAppend);

                    }, (e) => reject(e));

            },

            (e) => reject(e));

    });
}



