import React from 'react';
import {ayahUrl} from "./ayahUrl";


const AyahIndex = props => {

    let ayahList = [];

    if (props.ayahCount > 0) {
        for(let ayah = 1; ayah<= props.ayahCount; ayah++) {
            ayahList.push((
                <li key={ayah}>
                    <a
                        href={ayahUrl(props.sura, ayah)}
                        onClick={props.onIndexAyahSelected}
                        data-sura={props.sura}
                        data-ayah={ayah}
                    >
                        <label>الآية: </label>
                        <em>{ayah}</em>
                    </a>
                </li>
            ));
        }
    }

    return (
        <div>
            <ul>
                {ayahList}
            </ul>
        </div>
    );

};

export default AyahIndex;
