import React from 'react';
import {useQuranDb} from "../store/useQuranDb";
import PageContent from "./PageContent";

const NextPage = props => {

    const {pageNo, page, ...commonProps} = props;

    const curPageNo = parseInt(pageNo);

    const nextPageNo = curPageNo < 604 ? curPageNo + 1 : 604;

    const nextPage = useQuranDb(nextPageNo);


    return (
        <PageContent page={nextPage} pageNo={nextPageNo} {...commonProps} />
    );
    
};

export default NextPage;
