import React from 'react';

const SharedFonts = props => {


    const sharedFonts = props.page ? `@font-face {
        font-family : "f1-bsml";
        src         :  url("${props.path}QCF_BSML.TTF");
    }
    .f1-bsml {
        font-family     : "f1-bsml";
        justify-content : space-around !important;
    }` : '';


    return (
        <style>
            {sharedFonts}
        </style>
    );

};

export default SharedFonts;
