import React, {useEffect} from 'react';
import style from "./TafsirLayout.module.scss";
import TobBar from "./TobBar/TobBar";
import {IS_MOBILE_APP} from "../../../theme";


const TafsirLayout = props => {

    useEffect(() => {
        document.querySelector("meta[name='theme-color']").content = "#012D20";

        if (IS_MOBILE_APP && window.cordova) {
            window.StatusBar.backgroundColorByHexString("#012D20");
            document.querySelector('body').style.backgroundColor = '#012D20';
        }
    }, []);

    return (
        <div className={style.Tafsir}>
            <TobBar {...props} />
            <main>
                {props.children}
            </main>
        </div>
    );

};

export default TafsirLayout;
