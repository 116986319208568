import React, {useEffect} from 'react';
import style from "./ResultsList.module.scss";
import ResultItem from "./ResultItem";
import {markResult} from "lib/markResult";

const ResultsList = props => {

    useEffect(() => {
        markResult(props.keyword,"#search-res");
    }, [props.results]);  // eslint-disable-line

    const items = props.results.map((result, index) =>
        <li key={index}>
            <ResultItem result={result} />
        </li>);

    return (
        <section className={style.ResultList}>
            <ul id="search-res">
                {items}
            </ul>
        </section>
    );

};

ResultsList.dfaultProps = {
    results: []
}

export default ResultsList;
