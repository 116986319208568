import React, {useEffect, useState} from 'react';
import style from "./QuranIndex.module.scss";
import {useSuraList} from "../../Mushaf/store/useSuraList";
import {Link} from "react-router-dom";
import Grid from "@mui/material/Grid";
import Slide from '@mui/material/Slide';

const QuranIndex = props => {

    const [filter, setFilter] = useState('');

    let suraListView = '';


    const suraList = useSuraList();

    useEffect(() => {
        setFilter(props.filter);
    }, [props.filter]);


    if (suraList.length > 0) {

        suraListView = suraList.filter(sura => sura.name.includes(filter) || filter === '').map(sura => {

            let suraHexCode = '0x' + (61695 + parseInt(sura.sura)).toString(16);

            suraHexCode = String.fromCodePoint(suraHexCode);

            return (
                <Grid item key={sura.sura} xs={12} sm={6} md={4} lg={3}>
                    <Link to={"/tafsir/sura/" + sura.sura + "/ayah/1"}
                          data-sura={sura.sura}
                    >
                        <em>{sura.sura}</em>
                        <span title={sura.name} style={{fontFamily: "QCF4_QBSML"}}>{suraHexCode}</span>
                    </Link>
                </Grid>)
        });

    }

    return (
        <Slide in timeout={600} direction={"up"} mountOnEnter unmountOnExit>
            <div className={style.QuranIndex}>
                    <nav>
                        <Grid container spacing={2}>
                            {suraListView}
                        </Grid>
                    </nav>
            </div>
        </Slide>
    );

};

export default QuranIndex;
