import React, {useEffect, useState} from 'react';
import Slider from '@mui/material/Slider';
import {styled} from '@mui/material/styles';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import IconButton from "@mui/material/IconButton";


const PageSlider = styled(Slider)({
    color: '#012D20',
});

const MushafFooter = props => {

    const [value, setValue] = useState(null);


    useEffect(() => {
        if (props.page !== value) {
            setValue(props.page);
        }
    }, [props.page]) // eslint-disable-line


    return (
        <footer>
            {value === null ? '' :
                <>
                    <IconButton onClick={(e) => props.changeHandle(e, parseInt(props.page) -1 )}>
                        <ArrowForwardIosIcon/>
                    </IconButton>
                    <PageSlider
                        isRtl={true}
                        size="large"
                        aria-label="Small"
                        valueLabelDisplay="auto"
                        min={1}
                        max={604}
                        value={parseInt(value)}
                        onChange={(e, v) => setValue(v)}
                        onChangeCommitted={props.changeHandle}
                    />
                    <IconButton onClick={(e) => props.changeHandle(e, parseInt(props.page) +1 )}>
                        <ArrowBackIosNewIcon/>
                    </IconButton>
                </>}
        </footer>
    );

};

export default MushafFooter;
