import React, {useState, useEffect, useCallback} from 'react';
import Layout from "./Layout/Layout";
import QuranIndex from "./Index/QuranIndex";
import SearchResult from "../Search/SearchResult";
import {resultDb} from "../Search/store/resultDb";
import BaseContainer from "./Layout/BaseContainer";
import {isArabicString} from "lib/arabicDigits";
import {IS_MOBILE_APP} from "../../theme";

function Home() {

    const [find, setFind] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0);
        document.querySelector("meta[name='theme-color']").content = "#181E1E";

        if (IS_MOBILE_APP && window.cordova) {
            window.StatusBar.overlaysWebView(false);
            window.navigator.splashscreen.hide();
            window.StatusBar.backgroundColorByHexString("#181E1E");
            window.StatusBar.show();
            document.querySelector('body').style.backgroundColor = '#181E1E';
        }

        const kwd = window.althary['kwd'];
        if (kwd) setFind(kwd);

        window.addEventListener("beforeunload", clearSearch);
        return () => {
            window.removeEventListener("beforeunload", clearSearch);
        };
    }, []);  // eslint-disable-line


    const clearSearch = useCallback(() => {
        resultDb.res = [];
        window.althary['kwd'] = null;
    }, []);


    const searchHandler = useCallback((e) => {
        if (e !== undefined) {
            const keyword = e.target.value;
            if (find.length < 2) {
                clearSearch();
            }
            setFind(keyword);
        }
    }, []);  // eslint-disable-line

    const clearHandler = useCallback(() => {
        setFind('');
        clearSearch();
    }, []);  // eslint-disable-line


    return (
        <Layout
            find={find}
            onSearchHandler={searchHandler}
            onClearHandler={clearHandler}
        >
            <BaseContainer>
                <QuranIndex filter={find}/>
                {find.length > 1 && isArabicString(find) ? <SearchResult filter={find}/> : ''}
            </BaseContainer>
        </Layout>
    );

}

export default Home;
