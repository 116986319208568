import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import style from "./SearchSection.module.scss";
import ClearIcon from "@mui/icons-material/Clear"


const SearchSection = props => {

    const onEnter = (e) => {
        if (e.key === "Enter") {
            e.target.blur();
        }
    };

    return (
        <section className={style.Search}>

                <FormControl sx={{m: 1, width: '584px', maxWidth: '95%'}} variant="filled">
                    <OutlinedInput
                        classes={{
                            root: style.SearchInput,
                            focused: style.SearchInputFocused
                        }}
                        id="outlined-adornment-password"
                        type="text"
                        value={props.find}
                        onKeyUp={onEnter}
                        onChange={props.onSearchHandler}
                        endAdornment={
                            <InputAdornment position="end">
                                {props.find !== "" ?
                                    <IconButton
                                        onClick={props.onClearHandler}
                                        edge="end"
                                    >
                                        <ClearIcon/>
                                    </IconButton> : null}
                                <IconButton
                                    edge="end"
                                >
                                    <SearchIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        placeholder="ابحث عن...."
                    />
                </FormControl>

        </section>
    );

};

export default SearchSection;
