import React from 'react';

const BlankPage = props => {

    return (
        <section dir={"rtl"} key={props.index === undefined ? parseInt(props.pageNo)-1 : props.index} id={"p" + props.pageNo}>
            {" "}
        </section>
    );

};

export default BlankPage;
