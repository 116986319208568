import React from 'react';
import style from "./MushafIndexLayout.module.scss";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import PropTypes from "prop-types";
import SuraIndex from "../SuraIndex/SuraIndex";
import JuzIndex from "../JuzIndex/JuzIndex";
import Dialog from '@mui/material/Dialog';
import {IS_MOBILE_APP} from "../../../../theme";
import loadable from "@loadable/component";


function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <div>{children}</div>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

const MushafIndexLayout = props => {

    const [value, setValue] = React.useState(props.tab ? props.tab : 0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const Bookmarks = loadable(() => import("../Bookmarks/Bookmarks"));

    return (
        <div>
            <Dialog
                open={props.openIndex}
                onClose={props.onCloseIndex}
            >
                <div className={style.MushafIndex}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={value} onChange={handleChange}>
                            <Tab label="السورة" id={"index-sura-tab"}/>
                            <Tab label="الجزء" id={"index-juz-tab"}/>
                            {IS_MOBILE_APP ? <Tab label="العلامات" id={"index-bookmarks-tab"}/> : ""}
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <SuraIndex
                            onIndexAyahSelected={props.onIndexAyahSelected}
                        />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <JuzIndex
                            onIndexAyahSelected={props.onIndexAyahSelected}
                        />
                    </TabPanel>
                    {IS_MOBILE_APP ?
                    <TabPanel value={value} index={2}>
                        <Bookmarks
                            onIndexAyahSelected={props.onIndexAyahSelected}
                        />
                    </TabPanel> : ""}
                </div>
            </Dialog>
        </div>
    );

};

MushafIndexLayout.defaultProps = {
    tab: 0
}

export default MushafIndexLayout;
