import {tafsirDb} from "./tafsirDb";
import {useEffect, useState} from "react";
import {apiCall} from "@semiorbit/api-central";
import {LOAD_TAFSIR} from "./apiActions";
import {useSuraList} from "../../Mushaf/store/useSuraList";
import {useJuzList} from "../../Mushaf/store/useJuzList";
import {useAyahList} from "../../Mushaf/store/useAyahList";
import {IS_MOBILE_APP} from "../../../theme";


export const useTafsirDb = (sura, ayah) => {

    const suraList = useSuraList();

    const juzList = useJuzList();

    const ayahList = useAyahList();

    const [loaded, setLoaded] = useState(0);

    const [error, setError] = useState(0);

    useEffect(() => {

        if (tafsirDb.texts.hasOwnProperty(sura) &&
            tafsirDb.texts[sura].hasOwnProperty(ayah)) {
            setLoaded(1);
            setError(0);
        } else  {
            setLoaded(0);
            setError(0);
            apiCall(LOAD_TAFSIR, {'sura': sura, 'ayah': ayah})

                .then((response) => {
                    if (response.data) {
                        tafsirDb.texts[sura] = [];
                        tafsirDb.texts[sura][ayah] = [];
                        tafsirDb.texts[sura][ayah]['info'] = response.data.info;
                        tafsirDb.texts[sura][ayah]['txt'] = response.data.text;
                        setLoaded(1);
                    }
                }).catch(() => {
                    setLoaded(1);
                    setError(1);
                });

        }

    }, [sura, ayah]);


    const [pgInf, setPgInf] = useState(null);



    useEffect(() => {


        if (IS_MOBILE_APP) {

            if (suraList && suraList.hasOwnProperty(sura - 1) && juzList && ayahList && ayahList[sura - 1] !== undefined) {

                const juz = parseInt(ayahList[sura - 1][ayah - 1][0]);

                setPgInf({
                    page: parseInt(ayahList[sura - 1][ayah - 1][1]),
                    sura_arabic: suraList[sura - 1].name,
                    juz: juz,
                    ayah: ayah,
                    sura: sura,
                    juz_arabic: juzList[juz-1].juz_arabic
                });

            }

        }


    }, [suraList, juzList, ayahList, ayah, sura]);

    return  loaded && tafsirDb.texts.hasOwnProperty(sura) &&
            tafsirDb.texts[sura].hasOwnProperty(ayah) ?
        [IS_MOBILE_APP ? pgInf : tafsirDb.texts[sura][ayah]['info'],tafsirDb.texts[sura][ayah]['txt']] :
        [pgInf, error ? 0 : null];

};