import React from 'react';
import AppIcon from "assets/images/appicon.png"

const SplashScreen = () => {

    return window.cordova ? (
        <div style={{
            height: '100vh',
            width: '100vh',
            display: 'flex',
            alignItems: "center",
            alignContent: "center"
        }}>
            <img src={AppIcon} style={{
                maxWidth: '70%',
                margin: 'auto'
            }} alt=""/>
        </div>
    ) : <></>;

};

export default SplashScreen;
