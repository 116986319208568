import React, {useCallback, useState} from 'react';
import AyahIndex from "./AyahIndex";
import {useSuraList} from "../../store/useSuraList";
import style from "./SuraIndex.module.scss";
import {ayahUrl} from "./ayahUrl";

const SuraIndex = props => {

    const [filter, setFilter] = useState('');

    const [selectedSura, setSelectedSura] = useState([0, 0]);

    let ayahCount = 0;

    let firstSura = 0;

    let suraListView = '';


    const suraList = useSuraList();


    const suraSelected = useCallback((e) => {
        e.preventDefault();
        setSelectedSura([parseInt(e.currentTarget.dataset.sura), parseInt(e.currentTarget.dataset.ayahs)]);
    }, []);


    if (suraList.length > 0) {

        let n = 0;

        suraListView = suraList.filter(sura => sura.name.includes(filter) || filter === '').map(sura => {

            if (n === 0) {
                firstSura = sura.sura;
                ayahCount = sura.ayah_count;
            }
            n++;
            return (
                <li key={sura.sura}>
                    <a href={ayahUrl(sura.sura, 1)}
                       onClick={suraSelected}
                       data-sura={sura.sura}
                       data-ayahs={sura.ayah_count}
                       data-active={parseInt(sura.sura) === parseInt(selectedSura ? selectedSura : firstSura) ? "1" : "0"}
                    >
                        <em>{sura.sura}</em>
                        <span>{sura.name}</span>
                    </a>
                </li>)
        });

    }

    return (
        <div className={style.SuraIndex}>
            <section>
                <input id="filter-sura"
                       name="filter"
                       type="text"
                       value={filter}
                       onChange={e => setFilter(e.target.value)}
                       placeholder={"اسم السورة ..."}
                />
            </section>
            <nav>
                <ul>
                    {suraListView}
                </ul>
                {(selectedSura[0] || firstSura) && (ayahCount || selectedSura[1]) ?
                    <AyahIndex
                        sura={selectedSura[0] ? selectedSura[0] : firstSura}
                        ayahCount={selectedSura[1] ? selectedSura[1] : ayahCount}
                        onIndexAyahSelected={props.onIndexAyahSelected}
                    /> : ''}
            </nav>
        </div>
    );

};

export default SuraIndex;
