import React from 'react';
import Line from "./Line";


const PageLines = props => {

    const {page, pageNo, path} = props;


    const pageFonts = page ? Object.keys(page.font).map(file_no => page.font[file_no] === 'QCF_BSML.TTF' ? '' : (`
     @font-face {
        font-family : "f1-${pageNo}";
        src         : url("${path}${page.font[file_no]}");
    }`)
    + `
    .f1-${pageNo} {
        font-family : "f1-${pageNo}";
    }
    `) : '';

    const lines = page ? Object.keys(page.lines).map(line_no => {
        const line = page.lines[line_no];
        return (<Line
            key={line_no}
            line={line}
            line_no={line_no}
            {...props}
        />)
    }) : <span>&nbsp;</span>;

    return (
        <>
            <style>{pageFonts}</style>
            {lines}
        </>
    );
    
};

export default PageLines;
