import React, {useState} from 'react';
import GooglePlayBadge from "assets/images/google-play-badge.png"
import AppStoreBadge from "assets/images/app-store.svg"
import AppGalleryBadge from "assets/images/appgallery.png"
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close"

const AppBadges = () => {

    const isOpen = localStorage.getItem("app-store-bar");

    const reOpen = localStorage.getItem("app-store-exp");



    const [open, setOpen] = useState(isOpen === null || reOpen === null || reOpen < new Date().setDate(new Date().getDate()) ? 1 : parseInt(isOpen));

    const position = open ? "fixed" : "static";

    const bg = open ? "rgba(0,0,0,0.5)" : "transparent";

    return (
        <Slide in timeout={600} direction={"up"} mountOnEnter unmountOnExit>
            <div style={{
                position: position,
                bottom: "0",
                right: "0",
                left: "0",
                zIndex: "100",
                background: bg,
                display: "flex",
                flexFlow: "nowrap",
                alignItems: "center",
                justifyContent: "left",
                textAlign: "left",
                direction: "ltr"
            }}>
                {open ?
                    <IconButton
                        onClick={() => {
                            setOpen(false);
                            localStorage.setItem("app-store-bar", 0);
                            localStorage.setItem("app-store-exp", new Date().setDate(new Date().getDate() + 5));
                        }}
                        style={{
                            background: "rgba(0,0,0,0.7)",
                            position: "absolute",
                            top: "-15px",
                            right: "20px"
                        }}>
                        <Close style={{
                            color: "white",
                            fontSize: "small"
                        }}/>
                    </IconButton> : ''}


                {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={"https://play.google.com/store/apps/details?id=com.tafsiralthary.app"} target={"_blank"}>
                    <img src={GooglePlayBadge} alt={"Get it on Google Play"} style={{
                        maxWidth: "170px",
                        height: "50px",
                        padding: "5px"
                    }}/>
                </a>


                {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={"https://apps.apple.com/us/app/%D8%AA%D9%81%D8%B3%D9%8A%D8%B1-%D8%A7%D9%84%D9%82%D8%B1%D8%A2%D9%86-%D8%A7%D9%84%D8%AB%D8%B1%D9%8A-%D8%A7%D9%84%D8%AC%D8%A7%D9%85%D8%B9/id1620361842"} target={"_blank"}>
                    <img src={AppStoreBadge} alt={"Get it on App Store"} style={{
                        maxWidth: "170px",
                        height: "50px",
                        padding: "4px"
                    }}/>
                </a>


                {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                <a href={"https://appgallery.huawei.com/app/C105965197"} target={"_blank"}>
                    <img src={AppGalleryBadge} alt={"Explore it on App Gallery"} style={{
                        maxWidth: "170px",
                        height: "50px",
                        padding: "4px"
                    }}/>
                </a>

            </div>
        </Slide>
    );

};

export default AppBadges;
