import React from 'react';
import MushafIndexLayout from "./Layout/MushafIndexLayout";

function MushafIndex(props) {

    return (
        <MushafIndexLayout
            tab={props.tab}
            openIndex={props.openIndex}
            onCloseIndex={props.onCloseIndex}
            onIndexAyahSelected={props.onIndexAyahSelected}
        />
    );

}

export default MushafIndex;
