import React from 'react';
import style from "./MushafHeader.module.scss";

const MushafHeader = props => {

    return (
        <header className={style.MushafHeader}>
            <nav>
                <button onClick={props.openIndexHandler} className={style.SuraBtn}>{props.suraArabic}</button>
                <button onClick={props.openIndexHandler} className={style.JuzBtn}>{props.juzArabic}</button>
            </nav>
        </header>
    );

};

export default MushafHeader;
