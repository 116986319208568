import {resultDb} from "./resultDb";
import {useEffect, useState} from "react";
import {apiCall} from "@semiorbit/api-central";
import {SEARCH_DATA} from "./apiActions";
import {isJson} from "lib/validateJson";

export const useSearchResult = (keyword) => {

    const [loaded, setLoaded] = useState(0);



    useEffect(() => {

        let isCancelled = false;

        let mushafApiCallTimeout;

        const ac = new AbortController();

        setLoaded(0);


        if (keyword && keyword.length > 1) {

            if (resultDb.res.hasOwnProperty(keyword)) {
                setLoaded(1);
            } else {

                mushafApiCallTimeout = setTimeout(() => {


                    apiCall(SEARCH_DATA, {
                            signal: ac.signal,
                            'find': keyword
                        }
                    )

                        .then((response) => {
                            if (!isCancelled && response.data && isJson(response.data)) {
                                resultDb.res[keyword] = response.data;
                                window.althary['kwd'] = keyword;
                                setLoaded(1);
                            }
                        });

                }, 900);

            }

        } else  {
            resultDb.res = [];
        }

        return () => {
            isCancelled = true;
            ac.abort();
            clearTimeout(mushafApiCallTimeout);
        } // Abort both fetches on unmount

    }, [keyword]);

    return loaded ? resultDb.res[keyword] : null;

};