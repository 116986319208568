import React from 'react';

const TafsirMushafPane = props => {

    return (
        <aside>
            {props.children}
        </aside>
    );

};

export default TafsirMushafPane;
