import {bookmarksList} from "./bookmarksList";
import cordovaFileSystem from "../../../lib/cordovaTools/cordovaFileSystem";

const bookmarks = {

    store: () => {
        if (window.cordova) {
            cordovaFileSystem.write("bookmarks.json", JSON.stringify(bookmarksList.bookmarks));
        } else {
            localStorage.setItem('bookmarks', JSON.stringify(bookmarksList.bookmarks));
        }
    },

    findKey: (sura, ayah) => {
        return bookmarksList.bookmarks.findIndex(bookmark => {
            return bookmark.sura === sura && bookmark.ayah === ayah
        });
    },

    has: (sura, ayah) => {
        const k = bookmarks.findKey(sura, ayah);
        return k >= 0;
    },

    add: (sura, ayah, sura_arabic) => {
        if (!bookmarks.has(sura, ayah)) {
            bookmarksList.bookmarks.push({
                sura: sura,
                ayah: ayah,
                sura_arabic: sura_arabic
            });
            bookmarks.store();
        }
    },

    remove: (sura, ayah) => {
        const k = bookmarks.findKey(sura, ayah);
        if (k >= 0) {
            bookmarksList.bookmarks.splice(k, 1);
            bookmarks.store();
        }
    }


}

export default bookmarks;


