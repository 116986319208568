import React from 'react';
import {Link} from "react-router-dom";
import AppBadges from "./AppBadges";

const Footer = () => {

    return (
        <footer>
            <AppBadges/>
            <div style={{
                padding: "15px 12px"
            }}>
                {/* Safe link to the own website *//* eslint-disable-next-line react/jsx-no-target-blank */}
                All rights reserved &copy; 2022 by <a href="https://semiorbit.com" target="_blank">Semiorbit Solutions</a> &nbsp;|&nbsp;
                <Link to={"/privacy"}>Privacy Policy</Link>
            </div>
        </footer>
    );

};

export default Footer;
