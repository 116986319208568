import React from 'react';
import Menu from "./Menu/Menu";
import "./Layout.module.scss";
import TafsirAltharyTitle from "assets/images/tafsir-althary-title.png";
import SearchSection from "../../Search/Section/SearchSection";
import {IS_MOBILE_APP} from "../../../theme";
import Footer from "./Footer";
import loadable from '@loadable/component'


const Layout = props => {


    const MobileTools =  loadable(() => import("./Mobile/MobileTools"));

    return (
        <>
            <header>
                <Menu contentPage={props.contentPage} />
                <img src={TafsirAltharyTitle} alt="تفسير القرآن الثري الجامع في الإعجاز البياني واللغوي والعلمي" />
                {props.contentPage ? <br/> :
                <SearchSection
                    find={props.find}
                    onSearchHandler={props.onSearchHandler}
                    onClearHandler={props.onClearHandler}
                />}
            </header>
            <main>
                {props.children}
            </main>

            {IS_MOBILE_APP ? <MobileTools/> : <Footer/>}
        </>
    );

};

Layout.defaultProps = {
    contentPage: false
}

export default Layout;
