import React from 'react';
import {Route, Routes} from "react-router";
import Home from "./components/Home/Home";
import Tafsir from "./components/Tafsir/Tafsir";
import Article from "./components/Article/Article";


function App() {


    const routes = (

        <Routes>

            <Route path="/tafsir/:sura/:ayah" element={<Tafsir/>}/>

            <Route path="/tafsir/sura/:sura/ayah/:ayah" element={<Tafsir/>}/>

            <Route path="/tafsir/:sura" element={<Tafsir/>}/>

            <Route path="/tafsir" element={<Tafsir/>}/>

            <Route path="/about" element={<Article page="about"/>}/>

            <Route path="/privacy" element={<Article page="privacy"/>}/>

            <Route path="/" element={<Home/>}/>

        </Routes>

    );


    return (<>{routes}</>);

}

export default App;
