import { createTheme } from '@mui/material/styles';

export const IS_MOBILE_APP = false; //process.env.IS_MOBILE || window.cordova;

export const APP_TITLE = "تفسير القرآن الثري الجامع";

export const theme = createTheme({
    direction: 'rtl',
    palette: {
        primary: {
            main: "#012D20",
            light: "#FFCD2B"
        },
        secondary: {
            main: "#CE9A2B"
        }
    },
});