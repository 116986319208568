import React from 'react';
import Word from "../Ayah/Word";
import * as lineTypes from "./lineTypes";

const Line = props => {

    const {sura, ayah, pageNo, page, line, line_no} = props;

    const lnClass = (line.type === lineTypes.TYPE_AYAH ? 'f1-' + pageNo : 'f1-bsml');

    return (
        <div className={["line", "line-" + line.type, "line-" + line_no, lnClass].join(' ')}
             id={"ln-" + line_no}
             key={line_no}
             datatype={line.type}
        >

            {line.glyphs.map(gid =>
                <Word
                    key={gid}
                    data={page.glyphs[gid]}
                    clickHandler={props.onClick}
                    mouseOverHandler={props.onMouseOver}
                    mouseOutHandler={props.onMouseOut}
                    activeSura={sura}
                    activeAyah={ayah}
                />)}

        </div>)

};

export default Line;



//region "Constants"
/*
// const lineTypeStyle = useMemo(() => {
//
//     return {
//
//         [lineTypes.TYPE_AYAH]: styles.LineAyah,
//
//         [lineTypes.TYPE_SURA]: styles.LineSura,
//
//         [lineTypes.TYPE_BASMALLEH]: styles.LineBasmalleh
//     }
//
// }, []);
 */
//endregion