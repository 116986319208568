import {isJson} from "../../../lib/validateJson";
import cordovaFileSystem from "../../../lib/cordovaTools/cordovaFileSystem";

const lastPosition = {
    sura: null,
    ayah: null,

    get: () => new Promise((resolve, reject) => {
        if (lastPosition.sura === null) {
            lastPosition.load().then(() => {
                resolve(lastPosition);
            }).catch((e) => reject(e));
        } else {
            lastPosition.ayah ? resolve(lastPosition) :
                resolve({sura: 1, ayah: 1})
        }
    }),

    load: () =>
        new Promise((resolve, reject) => {
            let stored = null;
            if (window.cordova) {
                cordovaFileSystem.readJson("last_position.json").then((data) => {
                    lastPosition.set(data);
                    resolve(lastPosition);
                }).catch((e) => reject(e));
            } else {
                stored = isJson(stored) ? JSON.parse(localStorage.getItem('last_position')) : null;
                lastPosition.set(stored);
                resolve(lastPosition);
            }
        })
    ,

    set: (stored) => {
        const storedPos = stored ? stored : null;
        lastPosition.sura = storedPos && storedPos[0].sura ? storedPos[0].sura : 1;
        lastPosition.ayah = storedPos && storedPos[0].ayah ? storedPos[0].ayah : 1;
    },

    store: (sura, ayah) => {
        if ((sura === 1 && ayah === 1) || ayah === undefined) return;

        lastPosition.sura = sura;
        lastPosition.ayah = ayah;

        const data = JSON.stringify([{
            sura: lastPosition.sura,
            ayah: lastPosition.ayah
        }]);

        if (window.cordova) {
            cordovaFileSystem.write("last_position.json", data);
        } else {
            localStorage.setItem('last_position', data);
        }
    }

}

export default lastPosition;