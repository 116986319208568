import {suraList} from "./suraList";
import {useEffect, useState} from "react";
import data from "../resources/json/sura.json";

export const useSuraList = () => {

    const [loaded, setLoaded] = useState(0);

    useEffect(() => {

        setLoaded(0);


        const storedList = JSON.parse(localStorage.getItem('suraList'));
        if (storedList) suraList.sura = storedList;


        if (suraList.sura.length === 114) {
            setLoaded(1);
        } else {


            if (data) {
                suraList.sura = data;
                localStorage.setItem('suraList', JSON.stringify(suraList.sura));
                setLoaded(1);
            }


            /*
            apiCall(LOAD_SURA_LIST, {})

                .then((response) => {
                    if (response.data && isJson(response.data)) {
                        suraList.sura = response.data;
                        localStorage.setItem('suraList', JSON.stringify(suraList.sura));
                        setLoaded(1);
                    }
                });

             */


        }


    }, []);

    return loaded ? suraList.sura : [];

};