import React from 'react';

const BaseContainer = React.forwardRef((props, ref)  => {

    return (
        <div className={["base-container", props.className].join(' ')}>
            {props.children}
        </div>
    );

});

BaseContainer.defaultProps = {
    className: ""
};

export default BaseContainer;
