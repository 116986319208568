import Mark from "mark.js";

export const markResult = (keyword, result_elm) => {
    const markInstance = new Mark(document.querySelector(result_elm));
    markInstance.unmark({
        done: () => {
            markInstance.mark(keyword, {
                "ignorePunctuation": ["'ِ', 'ُ', 'ٓ', 'ٰ', 'ْ', 'ٌ', 'ٍ', 'ً', 'ّ', 'َ', 'ا', 'أ', 'آ', 'إ'"]
            });
        }
    });
}