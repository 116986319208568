import React, {useCallback, useEffect, useState} from 'react';
import TafsirLayout from "./Layout/TafsirLayout";
import Mushaf from "../Mushaf/Mushaf";
import {useNavigate, useParams} from "react-router";
import TafsirViewer from "./Viewer/TafsirViewer";
import TafsirMushafPane from "./Layout/TafsirMushafPane";
import {useTafsirDb} from "./store/useTafsirDb";
import {ayahUrl} from "../Mushaf/Index/SuraIndex/ayahUrl";
import GuideTour from "./GuideTour/GuideTour";

function Tafsir() {

    const [pageNo, setPageNo] = useState(null);

    const [openIndex, setOpenIndex] = useState(false);

    const [openTafsir, setOpenTafsir] = useState(false);

    const [runTour, setRunTour] = useState(false);


    const navigate = useNavigate();

    let {sura, ayah} = useParams();

    if (sura === undefined) sura = 1;

    if (ayah === undefined) ayah = 1;

    const [info, txt] = useTafsirDb(sura, ayah);


    useEffect(() => {
        if (info && pageNo !== info.page) setPageNo(info.page);
    }, [info]); // eslint-disable-line

    const wordClickHandler = useCallback((e) => {
        const sura = e.target.dataset.sura;
        const ayah = e.target.dataset.ayah;
        openTafsirState(true);
        if (ayah.toString() !== '')
            navigate(ayahUrl(sura, ayah))
    }, []); // eslint-disable-line


    const openIndexHandler = useCallback(() => {
        setOpenIndex(true);
    }, []);

    const closeIndexHandler = useCallback(() => {
        setOpenIndex(false);
    }, []);

    const indexAyahSelectedHandler = useCallback((e) => {
        e.preventDefault();
        const sura = e.currentTarget.dataset.sura;
        const ayah = e.currentTarget.dataset.ayah;
        if (sura.toString() !== '' && ayah.toString() !== '')
            navigate(ayahUrl(sura, ayah))
        setOpenIndex(false);
    }, []); // eslint-disable-line


    const openTafsirState = useCallback((state) => {
      setOpenTafsir(state);
    }, []);


    return (
        <TafsirLayout
            sura={sura}
            ayah={ayah}
            juz={info ? info.juz : ''}
            suraArabic={info ? info.sura_arabic : ''}
            openIndexHandler={openIndexHandler}
        >
            <TafsirMushafPane>
                <Mushaf pageNo={pageNo}
                        sura={sura}
                        ayah={ayah}
                        onClick={wordClickHandler}
                        onPageChange={(e, v) =>  (v !== pageNo) ? setPageNo(v <= 604 && v >= 1 ? v : pageNo) : null}
                        openIndex={openIndex}
                        onCloseIndex={closeIndexHandler}
                        onIndexAyahSelected={indexAyahSelectedHandler}
                        openIndexHandler={openIndexHandler}
                        onPageRendered={() => setRunTour(true)}
                />
            </TafsirMushafPane>
            <TafsirViewer
                sura={sura}
                ayah={ayah}
                text={txt}
                suraArabic={info ? info.sura_arabic : ''}
                open={openTafsir}
                openTafsirState={openTafsirState}
            />
            {localStorage.getItem('hide_tour') ? <></> :
            <GuideTour
                runTour={runTour}
                pageNo={pageNo}
                onClose={() => setRunTour(false)}
            />}
        </TafsirLayout>
    );
    
}

export default Tafsir;
