import React from 'react';
import PageLines from "./PageLines";

const PageContent = props => {

    return (
        <section dir={"rtl"} key={parseInt(props.pageNo)-1} id={"p" + props.pageNo}>
            <PageLines {...props} />
        </section>
    );
    
};

export default PageContent;
