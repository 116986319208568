import React, {useCallback} from 'react';
import MushafLayout from "./Layout/MushafLayout";
import Page from "./Page/Page";
import MushafIndex from "./Index/MushafIndex";
import {useQuranDb} from "./store/useQuranDb";

function Mushaf(props) {

    const page = useQuranDb(props.pageNo);

    const mouseOverHandler = useCallback((e) => {
        const ayah = e.target.dataset.ayah;
        const sura = e.target.dataset.sura;
        document.querySelectorAll('[data-sura="' + sura + '"][data-ayah="' + ayah + '"]').forEach((word) => {
           word.setAttribute('selected', 'true');
        });
    }, []);

    const mouseOutHandler = useCallback((e) => {
        const ayah = e.target.dataset.ayah;
        const sura = e.target.dataset.sura;
        document.querySelectorAll('[data-sura="' + sura + '"][data-ayah="' + ayah + '"]').forEach((word) => {
            word.removeAttribute('selected');
        });
    }, []);



    return (
        <MushafLayout
            page={props.pageNo}
            onPageChange={props.onPageChange}
            suraArabic={page ? ('سُورَة ' + page.info.sura_arabic) : ''}
            juzArabic={page ? page.info.juz_arabic : ''}
            openIndexHandler={props.openIndexHandler}
        >
            {props.pageNo ?
            <Page
                pageNo={props.pageNo}
                page={page}
                onClick={props.onClick}
                onMouseOver={mouseOverHandler}
                onMouseOut={mouseOutHandler}
                sura={props.sura}
                ayah={props.ayah}
                onPageChange={props.onPageChange}
                onPageRendered={props.onPageRendered}
            /> : ''}
            <MushafIndex
                openIndex={props.openIndex}
                onCloseIndex={props.onCloseIndex}
                onIndexAyahSelected={props.onIndexAyahSelected}
            />
        </MushafLayout>
    );
    
}

export default Mushaf;
