import React, {useEffect} from 'react';
import PageLayout from "./Layout/PageLayout";
import './Page.scss';
import FontsPreloader from "./FontsManager/FontsPreloader";
import SharedFonts from "./FontsManager/SharedFonts";
import PageContent from "./PageContent";
import BlankPage from "./BlankPage";
import PreviousPage from "./PreviousPage";
import NextPage from "./NextPage";
import {IS_MOBILE_APP} from "../../../theme";


function Page(props) {

    const {pageNo, page} = props;


    useEffect(() => {

        if (page && document) {

            const pageId = "#p" + pageNo.toString();

            const pgElem = document.querySelector(pageId);

            // Check if API exists
            if (document.fonts) {

                if (pgElem) {
                    pgElem.classList.add('font-loading');

                    // Do not block page loading
                    setTimeout(function () {
                        pgElem.classList.add('font-loading');
                        clearTimeout(window.wheelOnMushafPageTimeout);
                        window.wheelOnMushafPage = false;
                        document.fonts.load("1em 'f1-" + pageNo + "'").then(() => {
                            // Make font using elements visible
                            pgElem.classList.remove('font-loading');
                            window.wheelOnMushafPage = true;
                            props.onPageRendered();
                        });
                    }, 0);


                }

            } else {
                // Fallback if API does not exist
                if (pgElem) pgElem.classList.remove('font-loading')
            }

        }

    }, [page, pageNo]); // eslint-disable-line


    const path = page ? (IS_MOBILE_APP && window.cordova ?

        window.cordova.file.applicationDirectory + '/www/resources/fonts/QCF/' : page.font_path) : '';


    return (
        <>
            <SharedFonts page={page} path={path}/>
            {page ?
                <PageLayout
                    pageNo={pageNo}
                    onPageChange={props.onPageChange}
                >
                    {parseInt(pageNo) > 1 ? <PreviousPage {...props} path={path} /> : <></>}
                    <PageContent {...props} path={path} />
                    {parseInt(pageNo) < 604 ? <NextPage {...props} path={path} /> : <></>}
                </PageLayout>
                : <BlankPage pageNo={pageNo}/>}
            {IS_MOBILE_APP ? <></> : <FontsPreloader page={page} pageNo={pageNo} path={path} />}
        </>
    );

}

export default Page;
