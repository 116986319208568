import {quranDb} from "./quranDb";
import {useEffect, useState} from "react";
import {apiCall} from "@semiorbit/api-central";
import {LOAD_PAGE} from "./apiActions";
import {isJson} from "../../../lib/validateJson";
import {IS_MOBILE_APP} from "../../../theme";


export const useQuranDb = (page) => {

    const [loaded, setLoaded] = useState(0);

    useEffect(() => {

        setLoaded(0);

        if (page) {

            if (quranDb.pages.hasOwnProperty(page)) {
                setLoaded(1);
            } else {

                if (IS_MOBILE_APP && window.cordova) {

                    import("../../../lib/cordovaTools/cordovaFileSystem").then(({default: cordovaFileSystem}) => {

                        cordovaFileSystem.readJson(page + ".json", "www/resources/json/pages").then((data) => {
                            if (data) {
                                quranDb.pages[page] = data;
                                setLoaded(1);
                            }
                        });

                    });

                } else {

                    apiCall(LOAD_PAGE, {'page': page})

                        .then((response) => {
                            if (response.data && isJson(response.data)) {
                                quranDb.pages[page] = response.data;
                                setLoaded(1);
                            }
                        });

                }

            }

        }

    }, [page]);

    return loaded ? quranDb.pages[page] : null;

};