import React, {useEffect} from 'react';
import Slide from "@mui/material/Slide";
import {digitsEnToAr} from "lib/arabicDigits";
import {useArticleDb} from "./store/useArticleDb";
import Layout from "../Home/Layout/Layout";
import BaseContainer from "../Home/Layout/BaseContainer";
import IntroLinks from "./IntroLinks";

function Article(props) {

    useEffect(() => {
        document.querySelector("link[rel='canonical']").href = "https://tafsiralthary.com/" + props.page;
    }, [props.page]);

    const text = useArticleDb(props.page);

    const IS_ABOUT = (props.page === 'about');

    // noinspection CheckTagEmptyBody
    return (
        <Layout contentPage={true}>
            <BaseContainer>
                <Slide in timeout={600} direction={"up"} mountOnEnter unmountOnExit>
                    <article style={{
                        fontSize: "1.2em",
                        lineHeight: "1.8em"
                    }}>
                        {IS_ABOUT ? <IntroLinks /> : <></>}
                        <div dangerouslySetInnerHTML={{__html: text ? digitsEnToAr(text.toString()) : ''}}></div>
                    </article>
                </Slide>
            </BaseContainer>
        </Layout>
    );

}

Article.defaultProps = {
    page: ''
}

export default Article;




