import {juzList} from "./juzList";
import {useEffect, useState} from "react";


export const useJuzList = () => {

    const [loaded, setLoaded] = useState(0);

    useEffect(() => {

        setLoaded(0);

        const storedList = JSON.parse(localStorage.getItem('juzList'));
        if (storedList) juzList.juz = storedList;

        if (juzList.juz.length === 30) {
            setLoaded(1);
        } else {

            import("../resources/json/juz.json").then(({default: data}) => {
                if (data) {
                    juzList.juz = data;
                    localStorage.setItem('juzList', JSON.stringify(juzList.juz));
                    setLoaded(1);
                }
            });

            /*
            apiCall(LOAD_JUZ_LIST, {})

                .then((response) => {
                    if (response.data && isJson(response.data)) {
                        juzList.juz = response.data;
                        localStorage.setItem('juzList', JSON.stringify(juzList.juz));
                        setLoaded(1);
                    }
                });

             */

        }


    }, []);

    return loaded ? juzList.juz : [];

};