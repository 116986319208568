import React, {useEffect, useState} from 'react';
import style from "./TafsirViewer.module.scss";
import './TafsirArticle.scss';
import 'lib/arabicDigits';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import {Global} from '@emotion/react';
import useMediaQuery from "@mui/material/useMediaQuery";
import {digitsEnToAr} from "lib/arabicDigits";
import {markResult} from "../../../lib/markResult";
import Checkbox from '@mui/material/Checkbox';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import {IS_MOBILE_APP} from "../../../theme";
import bookmarks from "../../Mushaf/store/bookmarks";
import lastPosition from "../../Mushaf/store/lastPosition";
import NetworkError from "../../Home/Loader/NetworkError";
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import {ayahUrl} from "../../Mushaf/Index/SuraIndex/ayahUrl";
import {useSuraList} from "../../Mushaf/store/useSuraList";


const TafsirViewer = props => {


    const navigate = useNavigate();

    const isSmallScreen = useMediaQuery('(max-width: 959.9px), (max-height: 599px)');


    const [visible, setVisible] = useState(false);
    
    const [bookmarked, setBookmarked] = useState(false);

    const suraList = useSuraList();



    useEffect(() => {
        if (props.text !== null && props.open === true) setVisible(true);
    }, [props.text, props.open, isSmallScreen]);

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (!open) props.openTafsirState(false);
        setVisible(open);
    };

    useEffect(() => {
        const kwd = window.althary['kwd'];
        if (kwd) markResult(kwd, "#tafsir-text");
    }, [props.text]);




    useEffect(() => {
        if (IS_MOBILE_APP && props.ayah !== undefined) {
            setBookmarked(bookmarks.has(props.sura, props.ayah));
            lastPosition.store(props.sura, props.ayah);
        }
        document.querySelector("link[rel='canonical']").href = "https://tafsiralthary.com/tafsir/sura/" + props.sura + "/ayah/" + props.ayah;
    }, [props.ayah, props.sura]);  // eslint-disable-line


    const bookmarkBtn = (IS_MOBILE_APP ?
        <div style={{
            position: 'absolute',
            top: isSmallScreen ? '-18px' : '0',
            left: '5px'
        }}>
            <Checkbox
                checked={bookmarked}
                title={"وضع علامة"}
                icon={<BookmarkBorderIcon/>}
                checkedIcon={<BookmarkIcon/>}
                onChange={(e) => {
                    if (e.target.checked) {
                        bookmarks.add(props.sura, props.ayah, props.suraArabic)
                    } else {
                        bookmarks.remove(props.sura, props.ayah)
                    }
                    setBookmarked(e.target.checked)
                }}
            />
        </div> : ''
    );

    const ayah = parseInt(props.ayah);


    // noinspection CheckTagEmptyBody
    const article = (
            <article className={style.Article}>
                {bookmarkBtn}
                {props.text === 0 ? <NetworkError/> : ''}
                <div id="tafsir-text"
                     dangerouslySetInnerHTML={{__html: props.text ? digitsEnToAr(props.text.toString()) : ''}}></div>
                {props.text ?
                <div className={style.NavTools}>
                    {ayah > 1 ?
                    <Button variant="text" startIcon={<EastIcon />} onClick={() => navigate(ayahUrl(props.sura, ayah - 1))}>
                        الآية <label>{digitsEnToAr((ayah - 1).toString())}</label>
                    </Button> : <></>}
                    {ayah < parseInt(suraList[parseInt(props.sura)-1].ayah_count) ?
                    <Button variant="text" endIcon={<WestIcon />} onClick={() => navigate(ayahUrl(props.sura, ayah + 1))}>
                        الآية <label>{digitsEnToAr((ayah + 1).toString())}</label>
                    </Button> : <></>}
                </div> : <></>}
            </article>);

    return isSmallScreen ? (
        <>
            <Global
                styles={{
                    '.MuiDrawer-root > .MuiPaper-root': {
                        height: '82%',
                        borderTopLeftRadius: '20px !important',
                        borderTopRightRadius: '20px !important',
                        //overflow: 'visible', NEVER DO THIS!
                    },
                }}
            />
            <SwipeableDrawer
                swipeAreaWidth={20}
                anchor={"bottom"}
                open={visible}
                onClose={toggleDrawer(false)}
                onOpen={toggleDrawer(true)}
                disableDiscovery={true}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <div style={{
                    width: 60,
                    height: 6,
                    backgroundColor: 'lightgray',
                    borderRadius: 3,
                    position: 'absolute',
                    zIndex: 10,
                    top: 8,
                    left: 'calc(50% - 30px)'
                }}> </div>
                {article}
            </SwipeableDrawer>
        </>
    ) : article;

};


export default TafsirViewer;
