import axios from 'axios';
import {ApiCentralConfig} from "@semiorbit/api-central";

const active_lang = (/*localStorage.getItem('lang') ? localStorage.getItem('lang') :*/ "ar");

const instance = axios.create({
    //baseURL: 'http://192.168.1.6/althary/api/'  + active_lang,
    baseURL: 'https://api.tafsiralthary.com/' + active_lang
});

instance.interceptors.request.use((config) => {
    config.params = config.params || {};
    let token = localStorage.getItem('token');
    if (token) config.params['token'] = token;
    //config.params['lang'] = i18next.language ? i18next.language : localStorage.getItem('lang');
    // config.headers['x-token'] =  'yy';
    return config;
});


ApiCentralConfig.setAxiosInstance(instance);


export default instance;