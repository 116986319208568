import React from 'react';
import styles from "./MushafLayout.module.scss";
import MushafHeader from "./MushafHeader/MushafHeader";
import MushafFooter from "./MushafFooter/MushafFooter";

const MushafLayout = props => {

    return (
        <div className={styles.Mushaf}>
            <MushafHeader
                suraArabic={props.suraArabic}
                juzArabic={props.juzArabic}
                openIndexHandler={props.openIndexHandler}
            />
            <div className={styles.PagesContainer}>
                {props.children}
            </div>
            <MushafFooter changeHandle={props.onPageChange} page={props.page}/>
        </div>
    );

};

export default MushafLayout;
