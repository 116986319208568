import React, {useMemo} from 'react';
import Tour from "reactour";
import './reactour-rtl.scss';

const GuideTour = props => {

    const TOUR_STEPS = useMemo(() => [

        {
            selector: "#p" + props.pageNo + " .line-ayah:nth-of-type(6)",
            content: "اضغط على سطر الآية لإظهار التفسير",
            position: "bottom"

        },
        {
            selector: ".index-btn",
            content:
                "للانتقال إلى السور أو الآيات أو الأجزاء أو العلامات اضغط هنا",
            position: "bottom"
        },
        {
            selector: ".logo-btn",
            content:
                "للعودة إلى الواجهة الرئيسية اضغط هنا",
            position: "bottom"
        }

    ], [props.pageNo]);

    return (
        <Tour
            steps={TOUR_STEPS}
            isOpen={props.runTour}
            onRequestClose={() => {
                localStorage.setItem('hide_tour', 'true');
                props.onClose();
            }}
            closeWithMask={false}
        />
    );

};

export default GuideTour;
