import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';

// Create rtl cache
const cacheRtl = createCache({
    key: 'muirtl',
    stylisPlugins: [prefixer, rtlPlugin],
});

export function RTL(props) {
    return <CacheProvider value={cacheRtl}>{props.children}</CacheProvider>;
}

/*
import { create } from 'jss';
import rtl from 'jss-rtl';
import { StylesProvider, jssPreset } from '@mui/styles';
import React from "react";

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

export function RTL(props) {
    return (
        <StylesProvider jss={jss}>
            {props.children}
        </StylesProvider>
    );
}
*/
/**
 npm install stylis stylis-plugin-rtl
 npm install jss-rtl

 import { create } from 'jss';
 import rtl from 'jss-rtl';
 import { StylesProvider, jssPreset } from '@mui/styles';

 // Configure JSS
 const jss = create({
  plugins: [...jssPreset().plugins, rtl()],
});

 function RTL(props) {
  return <StylesProvider jss={jss}>{props.children}</StylesProvider>;
}

 */