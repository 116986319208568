import {articleDb} from "./articleDb";
import {useEffect, useState} from "react";
import {apiCall} from "@semiorbit/api-central";
import {isJson} from "lib/validateJson";
import {LOAD_ARTICLE} from "./apiActions";
import {IS_MOBILE_APP} from "../../../theme";


export const useArticleDb = (page) => {

    const [loaded, setLoaded] = useState(0);

    useEffect(() => {

        setLoaded(0);

        if (page) {

            if (articleDb.pages.hasOwnProperty(page)) {
                setLoaded(1);
            } else {


                if (IS_MOBILE_APP && window.cordova) {

                    import("../../../lib/cordovaTools/cordovaFileSystem").then(({default: cordovaFileSystem}) => {

                        cordovaFileSystem.readJson(page + ".json", "www/resources/json/articles").then((data) => {
                            if (data && data.hasOwnProperty('text')) {
                                articleDb.pages[page] = data.text;
                                setLoaded(1);
                            }
                        });

                    });

                } else {


                    apiCall(LOAD_ARTICLE, {'id': page})

                        .then((response) => {
                            if (response.data
                                && isJson(response.data)
                                && response.data.hasOwnProperty('text')
                            ) {
                                articleDb.pages[page] = response.data.text;
                                setLoaded(1);
                            }
                        });


                }

            }

        }

    }, [page]);

    return loaded ? articleDb.pages[page] : null;

};